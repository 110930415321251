import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

// import { MotionElement } from '@/components/utility/framer-motion-wrappers/MotionElement';
import usePreviousPlaylistPitches from '@/hooks/playlist/usePreviousPlaylistPitches';
import { PlaylistPitchModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';

import PreviousPlaylistPitches from './PreviousPlaylistPitches';
import RecommendedPlaylistsByGenres from './RecommendedPlaylistsByGenres';

enum PlaylistTabs {
  PREVIOUS_PLAYLISTS,
  RECOMMENDED_PLAYLISTS,
}

const PitchPlaylistRecommendations = ({
  outputPlaylists,
  canStep,
  playlists,
}: {
  playlists: SpotifyChosenPlaylistsModel[];
  outputPlaylists: (playlists: SpotifyChosenPlaylistsModel[]) => void;
  canStep: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [value, setTabValue] = useState<number>(PlaylistTabs.PREVIOUS_PLAYLISTS);

  const [chosenPlaylists, setChosenPlaylists] = useState<SpotifyChosenPlaylistsModel[]>(playlists || []);

  const { setValue, watch } = useFormContext<PlaylistPitchModel>();
  const formPlaylistPitches = watch('playlistPitches');

  const { previousPlaylistPitches } = usePreviousPlaylistPitches();

  useEffect(() => {
    if (!previousPlaylistPitches) return setTabValue(PlaylistTabs.RECOMMENDED_PLAYLISTS);
    setTabValue(PlaylistTabs.RECOMMENDED_PLAYLISTS);
  }, [previousPlaylistPitches]);

  useEffect(() => {
    outputPlaylists(chosenPlaylists);
  }, [chosenPlaylists, outputPlaylists]);

  const handleUpdatePlaylist = useCallback(
    (playlist: SpotifyChosenPlaylistsModel) => {
      if (chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyPlaylistId)) {
        setValue(
          'playlistPitches',
          formPlaylistPitches.map((pitches) => ({
            ...pitches,
            playlists:
              pitches.playlists && pitches.playlists.filter((pitch) => pitch.spotifyId !== playlist.spotifyPlaylistId),
          }))
        );
        setChosenPlaylists(chosenPlaylists.filter((item) => item.spotifyPlaylistId !== playlist.spotifyPlaylistId));
      } else {
        setValue(
          'playlistPitches',
          formPlaylistPitches.map((pitches) => ({
            ...pitches,
            playlists: pitches.playlists
              ? [
                  ...pitches.playlists,
                  { spotifyId: playlist.spotifyPlaylistId, recommendationSource: playlist.recommendationSource },
                ]
              : [{ spotifyId: playlist.spotifyPlaylistId, recommendationSource: playlist.recommendationSource }],
          }))
        );
        setChosenPlaylists([...chosenPlaylists, playlist]);
      }
    },
    [chosenPlaylists, setValue, formPlaylistPitches]
  );

  useEffect(() => {
    canStep(!!(chosenPlaylists.length > 0));
  }, [canStep, chosenPlaylists]);

  return (
    <>
      {/* <MotionElement isNext={true} delay={0.2}> */}
      <h3 data-testid="choose-playlists-title">{t('PLAYLISTING.RECOMMENDED-PLAYLISTS')}</h3>
      {/* </MotionElement> */}
      {/* <MotionElement isNext={true} delay={0.4}> */}
      <p className="text-faded mt16">
        {t('PLAYLISTING.RECOMMENDED-PLAYLISTS-DESCRIPTION')}{' '}
        <span
          className="text-white cursor-pointer underline"
          onClick={() => {
            navigate('/profile');
          }}
        >
          {t('PLAYLISTING.KEEP-YOUR-GENRES-UP-TO-DATE')}
        </span>
        <span>{t('PLAYLISTING.KEEP-YOUR-GENRES-UP-TO-DATE-PART-2')}</span>
      </p>{' '}
      {/* </MotionElement> */}
      {previousPlaylistPitches && previousPlaylistPitches?.items?.length > 0 && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '100%',
            marginTop: '20px',
            marginBottom: '8px',
          }}
        >
          <Tabs value={value} onChange={(_, newValue) => setTabValue(newValue)}>
            <Tab
              value={PlaylistTabs.RECOMMENDED_PLAYLISTS}
              label={t('Recommended playlists')}
              data-testid="recommended-playlists"
            />
            <Tab
              value={PlaylistTabs.PREVIOUS_PLAYLISTS}
              label={t('Previous playlists')}
              data-testid="previous-playlists"
            />
          </Tabs>
        </Box>
      )}
      {previousPlaylistPitches && value === PlaylistTabs.PREVIOUS_PLAYLISTS && (
        <PreviousPlaylistPitches
          previousPlaylistPitches={previousPlaylistPitches}
          handleUpdatePlaylist={handleUpdatePlaylist}
        />
      )}
      {value === PlaylistTabs.RECOMMENDED_PLAYLISTS && (
        <div className={!previousPlaylistPitches || previousPlaylistPitches.items.length === 0 ? 'mt48' : ''}>
          <RecommendedPlaylistsByGenres handleUpdatePlaylist={handleUpdatePlaylist} />
        </div>
      )}
    </>
  );
};

export default PitchPlaylistRecommendations;
