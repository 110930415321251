import React from 'react';

// import { MotionElement } from '@/components/utility/framer-motion-wrappers/MotionElement';
import PaymentOptions from '../../PaymentOptions';

export const PaymentOptionsPage = ({
  price,
  subscriptionDiscount,
}: {
  price: number;
  subscriptionDiscount: number;
}) => {
  return (
    <div key={3} className="max-w600 ml-auto mr-auto">
      {/* <MotionElement isNext={true} delay={0.2}> */}
      <h3>Payment</h3>
      {/* </MotionElement> */}
      <div className="card mt48">
        <PaymentOptions type="playlist" amount={price} discount={subscriptionDiscount} />
      </div>
    </div>
  );
};
