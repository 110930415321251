import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import Card from '@/components/utility/microcomponents/Card';
import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import useUserTracking from '@/hooks/useUserTracking';
import { PlaylistPitchModel, PlaylistPreviousPitchesAPIModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';

import PlaylistGenres from './PlaylistGenres';

const PreviousPlaylistPitches = ({
  previousPlaylistPitches,
  handleUpdatePlaylist,
}: {
  previousPlaylistPitches: PlaylistPreviousPitchesAPIModel;
  handleUpdatePlaylist: (playlist: SpotifyChosenPlaylistsModel) => void;
}) => {
  const { t } = useTranslation();
  const userTracking = useUserTracking();

  const hasRendered = useRef(false);

  const { watch } = useFormContext<PlaylistPitchModel>();
  const formPlaylistPitches = watch('playlistPitches');

  useEffect(() => {
    if (hasRendered.current) return;

    hasRendered.current = true;
    userTracking?.customMixpanelEvent('Screen View', {
      Product: 'Playlist Pitch',
      Type: 'Playlist Pitch',
      Screen: `Playlist Pitch - Previously Successful`,
      'Platform-Type': 'Web',
    });
  }, [userTracking]);

  return (
    <>
      <div className="d-flex gap20 flex-item-wrap jc-center mt20-lg-down">
        {previousPlaylistPitches.items.map((playlist) => (
          <ButtonComponent
            isCustom={true}
            key={playlist.id}
            className={`flex-w50p-21 playlist-recommendation-card previous-playlists cursor-pointer p0 br24 ${
              formPlaylistPitches[0].playlists?.map((item) => item.spotifyId).includes(playlist.spotifyId)
                ? 'to-do-card-border'
                : 'to-do-card-pre-border'
            }`}
            onClick={() =>
              handleUpdatePlaylist({
                spotifyPlaylistId: playlist.spotifyId,
                recommendationSource: 'previous-success',
                name: playlist.name,
                curatorName: playlist.curatorDetails.name,
                followers: playlist.stats.followers,
                image: playlist.image,
              })
            }
          >
            <div className={`card-inner cursor-pointer h100p br24 w100p`}>
              <div className="d-flex gap16">
                <div>
                  <object className="playlist-image" data={playlist.image} type="image/png">
                    <img className="playlist-image" src={Images.playlistIcon} alt="Fallback playlist img" />
                  </object>
                </div>
                <div className="text-left mb-auto">
                  <p className="fw-bold">{playlist.name}</p>
                  <p className="text-faded small">by {playlist.curatorDetails.name}</p>
                  <div className="d-flex mt8">
                    <img src={'/images/logos/spotify-logo.svg'} alt="" height={16} className="mt-auto mb-auto" />
                    <a href={playlist.url} target="_blank" rel="noreferrer" className="text-white mt-auto mb-auto">
                      <p className="ml8 small pt0">Listen on Spotify</p>
                    </a>
                    <Icon className="ml8 fs-sm text-faded pt4">open_in_new</Icon>
                  </div>
                </div>
                <div className="ml-auto">
                  {formPlaylistPitches[0].playlists?.map((item) => item.spotifyId).includes(playlist.spotifyId) ? (
                    <div>
                      <Icon className="text-blue">check_circle</Icon>
                    </div>
                  ) : (
                    <div>
                      <Icon className="material-symbols-outlined text-faded">circle</Icon>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt8">
                <PlaylistGenres genres={playlist.genres} />
              </div>
              <div className="d-flex mt4 gap4">
                <Card innerInner className="pt8 pb8">
                  <p className="fw-bold">{numberFormatter(playlist.stats.followers)}</p>
                  <p className="small text-faded">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</p>
                </Card>
                <Card className="flex-grow d-flex card-success gap10 pt8 pb8">
                  <h3 className="mt-auto mb-auto">🥳</h3>
                  <p className="small text-left mt-auto mb-auto">
                    {t('PLAYLISTING.YOU-HAVE-SUCCESSFULLY-PITCHED-BEFORE')}
                  </p>
                </Card>
              </div>
            </div>
          </ButtonComponent>
        ))}
      </div>
    </>
  );
};

export default PreviousPlaylistPitches;
